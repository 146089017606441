import React from "react";
import Layout from "../components/layout";
import styled from "styled-components";
import defaultImg from "../images/defaultImg.jpg";

const youtubeEl = styled.div``;

const youtube = () => {
  return (
    <Layout
      heroText={"youtube"}
      heroSubText={"A peak into my world, the community and how I stay fit."}
      headerImage={defaultImg}
    >
      <youtubeEl />
    </Layout>
  );
};

export default youtube;
